export const INDEX = '/';
export const POPULATION = '/population';
export const ELDERLY = '/elderly';
export const DEMENTIA = '/dementia';
export const SCENARIO_HOME = '/scenario';
export const DOCTOR = '/doctor-density';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const LOGIN = '/login';
export const IMPRINT = '/imprint';
export const PRIVACY = '/privacy';
export const DEMO = '/demo';
export const RESET = '/reset';
export const SET_PASSWORD = '/setpassword';
export const SOLUTION = '/solution';
export const CUSTOMERS = '/customers';
export const NEWSROOMS = '/newsroom';
export const NEWSROOM = '/newsroom/';
export const RELEASES = '/releases';
export const PROJECTS = '/projects';
export const SUPPORT = '/support';
export const SUPPORT_ITEM = '/support/';
export const DATA = '/data';
export const CARE_PROVIDERS = '/careproviders';
export const SCENARIOS = '/scenarios';
export const TEAM = '/team';
export const CARE_PROVIDER = '/careproviders/';
export const REGIONS = '/regions';
export const DASHBOARD = '/dashboard';
export const SCENARIO = '/scenarios/';
export const PROFILE = '/profile';
export const BUG_REPORT = '/bugreport';
export const NEWSLETTER = '/newsletter';

export const BAR_CHART = 'barChart';
export const LETTERS_CHART = 'lettersChart';
export const MAP_CHART = 'mapChart';
export const MULTI_CHART = 'multiChart';
export const HOME_PAGES = [
  {
    id: ELDERLY,
    to: ELDERLY,
    type: LETTERS_CHART,
    name: 'ALTERSBEVÖLKERUNG',
    description:
      'Der Anteil von Personen im Alter ab 65 Jahre ist schweizweit sehr unterschiedlich. Eine Gemeinde kann «jung» und die alterskorrelierte Krankheit Demenz weniger oft vorkommen, während eine Nachbarsgemeine bereits heute aktiv neue Gesundheitsangebote für Menschen entwickeln muss, die an Demenz erkrankt sind. Die demografische Entwicklung ist entscheidend für das Planen von Gesundheitsangeboten im Alter. Entsprechend wichtig ist es, die lokalen Gegebenheiten zu berücksichtigen.',
    color: '#ff93e7',
  },
  {
    id: DOCTOR,
    to: DOCTOR,
    type: MAP_CHART,
    name: 'ÄRZTEDICHTE',
    description:
      'Die Ärztedichte gibt an, wie viele Ärzte aus dem gewählten Fachgebiet pro 1000 Einwohner tätig sind. Die empfohlene Hausarztdichte beträgt beispielsweise 1. Je dunkler eine Fläche, desto höher die Ärztedichte. Die Karten kann via dem Play-Button in die Zukunft animiert werden. Durch Auswahl eines Kantons werden verschiedene Kennzahlen sichtbar. Die Zahl «Ärzte in Rente» gibt an, wie viele der heute aktiven Ärzte zum gegebenen Zeitpunkt in Rente sind. Diese Stellen müssen neu besetzt werden, um die angegebene Ärztedichte zu gewährleisten. Durch die Bevölkerungszunahme und die demografische Entwicklung müssen weitere Stellen geschaffen werden, ansonsten ist die Ärztedichte in der Tendenz rückgängig.',
    color: '#93ff75',
  },
  {
    id: POPULATION,
    to: POPULATION,
    type: BAR_CHART,
    name: 'ENTWICKLUNG 65+',
    description:
      'Der Anteil von Personen im Alter ab 65 Jahre in der Schweiz steigt. Der Anstieg ist aber lokal sehr unterschiedlich und auch nicht linear. Die demografische Entwicklung ist entscheidend für die Planung der Gesundheitsversorgung im Alter. Entsprechend ist es wichtig, die lokalen Gegebenheiten zu berücksichtigen. Das einfache Herunterbrechen von Bundes- oder Kantonsdaten kann zu falschen Annahmen führen.',
    color: '#ff7500',
  },
  {
    id: DEMENTIA,
    to: DEMENTIA,
    type: BAR_CHART,
    name: 'DEMENZ',
    description:
      "In der Schweiz leben heute 153'000 Menschen mit Demenz. Die Krankheit ist alterskorreliert, leben also in einer Gemeinde zukünftig mehr ältere Menschen, wird es zu einem Anstieg bei den Neuerkrankungen kommen.",
    color: '#9828ff',
  },
  // {
  //   id: SCENARIO_HOME,
  //   to: SCENARIO_HOME,
  //   type: MULTI_CHART,
  //   name: 'Langzeitversorgung',
  //   description: '',
  //   color: '#ebebeb',
  // },
];
