import React, { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { cond, T } from 'ramda';

import * as ACCESS from 'permissions/consts';
import {
  INDEX,
  POPULATION,
  ELDERLY,
  DEMENTIA,
  DOCTOR,
  // SCENARIO_HOME,
  ABOUT,
  CONTACT,
  LOGIN,
  IMPRINT,
  PRIVACY,
  DEMO,
  RESET,
  SET_PASSWORD,
  SOLUTION,
  CUSTOMERS,
  NEWSROOMS,
  NEWSROOM,
  DATA,
  RELEASES,
  CARE_PROVIDERS,
  SCENARIOS,
  TEAM,
  CARE_PROVIDER,
  REGIONS,
  DASHBOARD,
  SCENARIO,
  PROFILE,
  PROJECTS,
  BUG_REPORT,
  SUPPORT,
  SUPPORT_ITEM,
  NEWSLETTER,
} from 'utils/routes';
import Home from 'pages/Home';

import Layout from './Layouts/Layout';
import Extern from './Layouts/Extern';
import FullScreen from './Layouts/FullScreen';

const Imprint = lazy(() => import('pages/Imprint'));
const Privacy = lazy(() => import('pages/Privacy'));
const Login = lazy(() => import('pages/Login'));
const Reset = lazy(() => import('pages/Reset'));
const About = lazy(() => import('pages/About'));
const Contact = lazy(() => import('pages/Contact'));
const Support = lazy(() => import('pages/Support'));
const Solution = lazy(() => import('pages/Solution'));
const Customers = lazy(() => import('pages/Customers'));
const Newsrooms = lazy(() => import('pages/Newsrooms'));
const Newsroom = lazy(() => import('pages/Newsroom'));
const Data = lazy(() => import('pages/Data'));
const Team = lazy(() => import('pages/Team'));
const CarProviders = lazy(() => import('pages/CarProviders'));
const Scenarios = lazy(() => import('pages/Scenarios'));
const Scenario = lazy(() => import('pages/Scenario'));
const CareProvider = lazy(() => import('pages/CareProvider'));
const Regions = lazy(() => import('pages/Regions'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Profile = lazy(() => import('pages/Profile'));
const Releases = lazy(() => import('pages/Releases'));
const Project = lazy(() => import('pages/Project'));
const BugReport = lazy(() => import('pages/BugReport'));
const Newsletter = lazy(() => import('pages/Newsletter'));
const Demo = lazy(() => import('pages/Demo'));
const HomeItem = lazy(() => import('pages/Home/Infographic'));

const ROUTES = [
  {
    path: INDEX,
    layout: Layout,
    component: Home,
    meta: {},
  },
  ...[POPULATION, ELDERLY, DEMENTIA, DOCTOR /* , SCENARIO_HOME */].map((path) => ({
    path,
    layout: Layout,
    component: () => <HomeItem key={path} />,
    meta: {},
  })),
  {
    path: ABOUT,
    layout: FullScreen,
    component: About,
    meta: {
      title: 'Über uns',
      description:
        'CuraData liefert datengestützte und wissenschaftlich fundierte Erkenntnisse, damit Gemeinden, Regionen und Kantone rund um die künftigen Gesundheitsleistungen entscheiden können.',
    },
  },
  {
    path: CONTACT,
    layout: FullScreen,
    component: Contact,
    meta: {
      title: 'Kontakt',
      description: 'CuraData hilft Ihnen gerne weiter. Kontaktieren Sie uns, indem Sie uns eine Nachricht schreiben.',
    },
  },
  {
    path: RELEASES,
    layout: FullScreen,
    component: Releases,
    meta: {
      title: 'Releases',
      description: 'Informieren Sie sich über Updates und die neusten CuraData-Funktionalitäten.',
    },
  },
  {
    path: SUPPORT,
    layout: FullScreen,
    component: Support,
    meta: {
      title: 'Hilfebereich',
      description:
        'Der CuraData-Support hilft Ihnen gerne weiter. Erfahren Sie mehr über beliebte Themen und finden Sie Artikel, die Sie bei der Nutzung von CuraData unterstützen.',
    },
  },
  {
    path: `${SUPPORT_ITEM}:id`,
    layout: FullScreen,
    component: Support,
    meta: {
      title: 'Hilfebereich',
      description:
        'Der CuraData-Support hilft Ihnen gerne weiter. Erfahren Sie mehr über beliebte Themen und finden Sie Artikel, die Sie bei der Nutzung von CuraData unterstützen.',
    },
  },
  {
    path: LOGIN,
    layout: FullScreen,
    component: Login,
    meta: {
      title: 'Anmelden',
      description: 'Melden Sie sich an, um auf Ihr CuraData-Konto zuzugreifen.',
      withoutIndexing: true,
    },
  },
  {
    path: IMPRINT,
    layout: FullScreen,
    component: Imprint,
    meta: {
      title: 'Impressum',
      description: 'CuraData ist ein Schweizer Digital-Health-Unternehmen und wurde 2017 gegründet.',
    },
  },
  {
    path: PRIVACY,
    layout: FullScreen,
    component: Privacy,
    meta: {
      title: 'Datenschutz',
    },
  },
  {
    path: DEMO,
    layout: Extern,
    component: Demo,
    meta: {
      title: 'Demo',
      description:
        'Lernen Sie in unserer Live-Demo, wie Sie mit CuraData die Gesundheitsversorgung der Altersbevölkerung planen.',
    },
  },
  {
    path: RESET,
    layout: FullScreen,
    component: Reset,
    meta: {
      title: 'Passwort vergessen',
      withoutIndexing: true,
    },
  },
  {
    path: SET_PASSWORD,
    layout: FullScreen,
    component: Reset,
    meta: {
      title: 'Passwort setzen',
      withoutIndexing: true,
    },
  },
  {
    path: SOLUTION,
    layout: Extern,
    component: Solution,
    meta: {
      title: 'Produkt',
      description: 'Gesundheitsversorgung der Altersbevölkerung – CuraData hat die Antworten auf Ihre Fragen.',
    },
  },
  {
    path: CUSTOMERS,
    layout: FullScreen,
    component: Customers,
    meta: {
      title: 'Kunden',
      description:
        'Mit CuraData sind Sie Mitgestalter einer digitalen Plattform zur Gesundheitsversorgung der Altersbevölkerung.',
    },
  },
  {
    path: NEWSROOMS,
    layout: FullScreen,
    component: Newsrooms,
    meta: {
      title: 'Newsroom',
      description:
        'Der CuraData-Newsroom liefert wichtige Neuigkeiten zu CuraData. Lesen Sie unsere neuesten Artikel und erfahren Sie mehr über unsere Produkt-Updates.',
    },
  },
  {
    path: `${NEWSROOM}:id`,
    layout: FullScreen,
    component: Newsroom,
    meta: {
      title: 'Newsroom',
    },
  },
  {
    path: DATA,
    layout: FullScreen,
    component: Data,
    meta: {
      title: 'Daten',
      description: 'CuraData verknüpft verschiedene Datenquellen und stellt Ihnen diese in Echtzeit zur Verfügung.',
    },
  },
  {
    path: CARE_PROVIDERS,
    layout: FullScreen,
    access: ACCESS.CARE_PROVIDERS,
    component: CarProviders,
    meta: {
      title: 'Leistungserbringer',
      withoutIndexing: true,
    },
  },
  {
    path: SCENARIOS,
    layout: FullScreen,
    access: ACCESS.SCENARIOS,
    component: Scenarios,
    meta: {
      title: 'Szenarien',
      withoutIndexing: true,
    },
  },
  {
    path: TEAM,
    layout: FullScreen,
    access: ACCESS.TEAM,
    component: Team,
    meta: {
      title: 'Team',
      withoutIndexing: true,
    },
  },
  {
    path: `${CARE_PROVIDER}:id`,
    layout: FullScreen,
    access: ACCESS.CARE_PROVIDER,
    component: CareProvider,
    meta: {
      title: 'Leistungserbringer',
      withoutIndexing: true,
    },
  },
  {
    path: REGIONS,
    layout: FullScreen,
    access: ACCESS.REGIONS,
    component: Regions,
    meta: {
      title: 'Regionen',
      withoutIndexing: true,
    },
  },
  {
    path: DASHBOARD,
    layout: FullScreen,
    access: ACCESS.DASHBOARD,
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      withoutIndexing: true,
    },
  },
  {
    path: `${SCENARIO}:id`,
    layout: FullScreen,
    access: ACCESS.SCENARIOS,
    component: Scenario,
    meta: {
      title: 'Szenarien',
      withoutIndexing: true,
    },
  },
  {
    path: PROFILE,
    layout: FullScreen,
    access: ACCESS.USER,
    component: Profile,
    meta: {
      title: 'Nutzerprofil',
      withoutIndexing: true,
    },
  },
  {
    path: PROJECTS,
    layout: FullScreen,
    access: ACCESS.PROJECTS,
    component: Project,
    meta: {
      title: 'Projekte',
      withoutIndexing: true,
    },
  },
  {
    path: BUG_REPORT,
    layout: FullScreen,
    access: ACCESS.BUG_REPORT,
    component: BugReport,
    meta: {
      title: 'Feedback',
      withoutIndexing: true,
    },
  },
  {
    path: NEWSLETTER,
    layout: FullScreen,
    component: Newsletter,
    meta: {
      title: 'Newsletter',
    },
  },
];

export const routesMapping = cond([
  ...ROUTES.map((route) => [(currentPath) => matchPath(route.path, currentPath), () => route]),
  [T, () => ({})],
]);

export default ROUTES;
